.callout {
  margin: 25px auto 70px auto;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    margin: 0 auto 70px auto;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  .carousel-item {
    @include media-breakpoint-down(xs) {
      height: 250px;
    }
    height: 450px;
    background-size: 100vw;
    background-repeat: no-repeat;
    background-color: $brand-secondary;

    @include media-breakpoint-down(xs) {
        height: 155px;
        background-size: cover;
        background-position: center;
    }
    @media (max-width:320px){
        height: 106px;
        background-size: cover;
    }
    @include media-breakpoint-up(sm) {
        height: 250px;
        background-size: cover;
    }
    @include media-breakpoint-up(sm) {
        height: 225px;
        background-size: cover;
    }
    @include media-breakpoint-up(md) {
        height: 211px;
        background-size: cover;
    }
    @include media-breakpoint-up(lg) {
        height: 262px;
        background-size: cover;
    }
    @include media-breakpoint-up(xl) {
        height: 375px;
        background-size: cover;
    }
    @media (min-width: 1920px){
        height: 450px;
        background-size: cover;
    }
    @media (min-width:2560px){
        background-size: cover;
        height: 535px;
    }
  }
}

.callout.home {
  .carousel-item {
    min-height: 185px;
    @include media-breakpoint-up(md) {
        height: 700px;
      }
      @include media-breakpoint-down(xs) {
          height: 250px;
          background-size: cover;
      }
      @media (max-width:320px){
          background-size: cover;
      }
      @include media-breakpoint-up(xs) {
          height: 300px;
      }
      @include media-breakpoint-up(sm) {
          height: 370px;
          background-size: cover;
       }
       @include media-breakpoint-up(sm) {
          background-size: cover;
       }
      @include media-breakpoint-up(md) {
          height: 500px;
          background-size: cover;
       }
      @include media-breakpoint-up(lg) {
          height: 428px;
          background-size: cover;
      }
      @include media-breakpoint-up(xl) {
          height: 516px;
      }
      @media (min-width: 1920px){
          height: 700px;
      }
      @media (min-width:2560px){
          height: 700px;
      }
  }

  .callout-caption-box {
    background-color: rgba($color: $brand-primary, $alpha: 0.82);
  }
}

.callout-caption {
  @extend .container;
  height: 100%;
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-up(xl) {
    width: 1310px;
    max-width: 1310px;
  }

  @include media-breakpoint-down(xl) {
    width: auto;
    max-width: auto;
  }
}

.callout-caption-box {
  display: none;
  padding: 35px 200px 35px 85px;
  background-color: transparentize($brand-primary, 0.2);
  color: $white;
  flex-wrap: wrap;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    justify-content: flex-start;
    @include make-col(12);
    display: flex;
    top: 20%;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 35px 20px 35px;
  }

    @include media-breakpoint-up(md) {
        padding: 30px 37px;
    }

    @include media-breakpoint-up(xs) {
        display: none;
    }

  h2 {
    font-size: $font-size-h1 * 1.3;
    font-weight: $font-weight-bold;
    background: none;
    padding-top: 0;
    margin-bottom: 1.2rem;
    height: auto;
  }

  h2, p {
    color: $white;
    display: block;
  }

  p:last-child {
    margin-top: 1.3rem;
  }

  a {
    @extend .btn-brand-secondary;
  }
}
