.neos-nodetypes-text {
  .table {
    @extend .table-striped;
    border-top: 3px solid $brand-primary;
    color: $brand-primary;

    tbody {
      tr:nth-of-type(even) {
        background-color: $color-gray;
      }

      tr:nth-of-type(odd) {
        background-color: transparent;
      }

      tr {
        td {
          border-right: 1px solid $brand-primary;
          width: 245px;

          @include media-breakpoint-down(xs) {
              font-size: 14px;
              padding-left: 5px;
              padding-right: 5px;
          }

          p  {
            font-size: 20.8px;
            font-weight: 400;

            @include media-breakpoint-down(xs) {
                font-size: 14px;
            }
          }
        }
      }
    }
  }
}
