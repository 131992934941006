.download {
  border-top: 5px solid $brand-primary;
  margin-bottom: 50px;

  h2 {
    background: none;
    line-height: 1.3rem;
    margin-bottom: 17px;
    height: auto;
  }

  p.download-link {
    margin-top: 2rem;
  }

}