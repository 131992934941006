.product {
  border-top: 5px solid $brand-primary;
  padding-top: 15px;
  margin-bottom: 50px;

  .product-inner-content {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }

    .product-image {
      @extend .col;
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 300px;
      margin-top: 10px;
      border: 1px solid $color-gray-dark;

        figure {
          display: flex;
          align-items: center;
          margin-bottom: 0;

          img {
            width: 100%;
          }
        }
    }

    .product-description {
      @include make-col(12);
      margin-bottom: 25px;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      padding-right: 35px;

      p {
        margin-bottom: 0.1rem;
      }

      ul {
        margin-bottom: 1.5rem;
      }

      p.product-download-link {
        padding-top: 25px;

        a.btn-brand-primary {
          &:hover {
            text-decoration: none;
            background-color: $brand-primary;
            color: $white;
          }
        }
      }
    }

    h3 {
      line-height: 1.3rem;
      margin-bottom: 17px;
    }
  }

  &.product-secondary {

    .product-inner {
      border-top: 5px solid $color-brown-light;
      padding-top: 15px;

      h3 {
        a {
          color: $color-brown-light;
        }
      }

      .product-description {
        p {
          color: $color-black;
        }

        ul {
          li {
            color: $color-black;
          }
        }
      }
    }
  }

  &.col-12 {
    .product-image {
      @include make-col(3);
    }
  }

}
