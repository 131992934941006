.category-teaser {

  @extend %responsivnes;

  .teaser-inner-content {
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }
    background-color: $color-gray-light;

    .teaser-image {
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      display: flex;
      background-color: $color-gray-light;
      width: 100%;
      height: 175px;

      a, div {
        display: flex;
        figure {
          display: flex;
          align-items: center;
          margin-bottom: 0;

          img {
            width: 100vw;
            max-height: 154px;
          }
        }
      }
    }

    .teaser-description {
      padding: 20px 35px;
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      p {
        margin-bottom: 0.1rem;
      }

      ul {
        margin-bottom: 1.5rem;
      }
    }

    h3 {
      line-height: 1.3rem;
      margin-bottom: 17px;
    }
  }

  &.teaser-secondary {

    .teaser-inner {
      border-top: 5px solid $color-brown-light;
      padding-top: 15px;

      h3 {
        a {
          color: $color-brown-light;
        }
      }

      .teaser-description {
        p {
          color: $color-black;
        }

        ul {
          li {
            color: $color-black;
          }
        }
      }
    }
  }

  &.cols-12 {
    .teaser-image {
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(3);
      }
    }
  }

}
