.grid-content.full {
  @extend .container-fluid;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  .grid-content-col {

    &.col-12 {
      padding-left: 0;
      padding-right: 0;

      .neos-nodetypes-image {
        img {
          width: 100%;
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.grid-content {
  @extend .container;
  margin-bottom: 70px;
}


.grid-content-col {
  &.inner-well {
    > .neos-contentcollection {
      @extend .well;
    }
  }

  &.margin-top-25 {
    margin-top: 25px;

    @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
    }
  }

  &.margin-top-50 {
    margin-top: 50px;
  }

  &.margin-bottom-25 {
    margin-bottom: 25px;
  }

  &.margin-bottom-50 {
    margin-bottom: 50px;
  }
}
