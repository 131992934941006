.blog {
    figure {
        img {
            height: auto;
            max-width: 100%;
            border: 4px solid $color-blue;
        }
    }

    .neos-nodetypes-image {
        figure {
            img {
                border: 4px solid $color-blue;
            }
        }
    }
}

.blog-teaser {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 70px;

    .blog-teaser--category {
        @extend .col;
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: capitalize;
    }

    > div {
        display: flex;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    .blog-teaser--content, .blog-teaser--image {
        @extend .col;
        @include make-col(6);

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        h2 {

            line-height: 30px;

            strong {
                font-weight: $font-weight-normal;
            }
        }

        .instanode-headstart-thumbimage {
            img {
                border: 4px solid $color-blue;
            }
        }
    }

    .btn-brand-primary {
        display: inline-block;
        margin-top: 20px;
    }

    .blog-teaser--image {
        @include media-breakpoint-down(sm) {
            margin-top: 34px;
        }
    }
}

.grid-content-container {
    &.back {
        margin-bottom: 50px;

        a {
          position: relative;
          transition: all ease-out 0.5s;
          left: 25px;

          &:hover {

              &::before {
                  transform: translateX(-5px);
              }
          }

          &::before {
              content: '<<';
              position: absolute;
              left: -25px;
              display: inline;
              transition: all ease-in 0.5s;
          }
        }
    }

    .grid-content-col {
        &.col-8 {
            margin-top: 20px;
        }
    }

    &.blog {
        @include media-breakpoint-down(md) {
            .grid-content-col.col-6 {
                @include make-col(12);
            }
        }

        .neos-nodetypes-headline {
            h1 {
                strong {
                    text-transform: initial;
                    font-weight: $font-weight-normal;
                    letter-spacing: 0;
                }
            }
        }
    }
}
