$header-logo-height-md: 45px;
$header-logo-height-xs: $header-logo-height-md/2;

.header {
  @extend .container-fluid;
  padding-top: 10px;
  padding-bottom: 25px;
  position: relative;

  .header-content {
    @extend .container;
  }

  @include media-breakpoint-down(md) {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: white;
    box-shadow: 0 -3px 6px $color-blue;

    .header-nav-content {
        .header-nav-list {
            max-height: 445px;
            overflow: scroll;
            min-height: auto;
            height: 100%;
        }
    }
  }

  @include media-breakpoint-down(sm) {
    min-height: 80px;
    padding-bottom: 10px;
    padding-right: 0;
    padding-left: 0;

    .header-nav-content {
        .header-nav-list {
            max-height: calc(100vh - 70px);
        }
    }
  }
}

.header-logo {
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }

  @include media-breakpoint-down(sm) {
    position: absolute !important;
    left: 30px;
    top:25px;
  }
  display: flex;
  align-items: flex-end;

  img {
    height: $header-logo-height-xs;

    @include media-breakpoint-up(md) {
      height: $header-logo-height-md;
    }
  }
}

.header-shop {
    width: fit-content;
    position: fixed;
    right: 0;
    top: 63px;
    z-index: 1000;

    @include media-breakpoint-up(lg) {
        top: 121px;
    }

    @media (min-width: 1440px) {
      bottom: 0;
      right: 0;
      left: auto;
    }

    @include media-breakpoint-down(md) {
        bottom: 0;
        top: auto;

        img {
            width: 85px;
            height: 100%;
        }
    }
}

.header-nav {
  @include make-col(12);
  @include media-breakpoint-up(md) {
    position: relative;
    margin-top: 40px;
    @include make-col(9);
  }

  @include media-breakpoint-only(md) {
    margin-top: 0px;
    position: unset;
    max-width: 100%;
  }

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  a {
    color: inherit;
    font-size: 1.1rem;
    
    @include  media-breakpoint-only(lg) {
      font-size: 16px;
    }

    &:hover {
      color: darken($text-color, 15%);
    }
  }

  li {
    &.active > a,
    &.current > a {
      font-weight: 500;
    }
  }
}

.header-nav-list,
.header-nav-sub {
  list-style-type: none;
}

.header-nav-list {
  padding: 0;
  margin-bottom: 0;
  @include make-col(12);
  @include media-breakpoint-up(lg) {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }

  @include media-breakpoint-up(lg) {
    text-align: right;

    li {
      line-height: 1rem;
      display: flex;
      margin-left: 1.8rem;

      &.current, &.active {
        a {
          color: $brand-primary;
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
      li {
          margin-left: 1.3rem;
      }
  }

  @include media-breakpoint-down(md) {
    li {
        margin-left: 0;
    }
}

  li.current .header-nav-sub,
  li.active .header-nav-sub {
    display: block;
    @include media-breakpoint-down(md) {
        display: contents;
    }
  }

  .header-nav-sub {
    @include media-breakpoint-up(sm) {
      display: none;
      position: absolute;
      top: 28px;
      right: 0;
      padding: 15px;
      background-color: $brand-primary;
      margin: 13px 0;
      z-index: 200;
      height: 50px;
      width: 700px;
    }



    .second-level-sub-navigation {
      list-style-type: none;
      padding-left: 15px;
      @include media-breakpoint-up(md) {
        display: flex;
        padding-left: 0;
      }

      @include media-breakpoint-down(md) {
        display: block;
        padding-left: 20px;
      }

      li {
        border: 0;
        padding: 5px 15px;

        @include media-breakpoint-up(md) {
          padding: 0;
        }

        a {
          color: $white;
          font-weight: $font-weight-normal;
        }

        &.current {
          a {
            font-weight: $font-weight-bold;
          }
        }
      }

      li:first-child {
        margin-left: 0;
      }
    }
  }
}

.header-nav-toggle {
  @include make-col-offset(9);
  position: absolute;
  top: 25px;
  right: 30px;
  z-index: 101;
  color: #000;

  @include media-breakpoint-up(md) {
    top: 50px;
    right: auto;
  }

  @include media-breakpoint-down(md) {
    top: 31px;
  }

  @include media-breakpoint-down(sm) {
    top: 24px;
    right: 10px;
  }

  .navbar-toggler {
    outline: none;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .header-nav-content.collapse {
    display: block;
    visibility: visible;
    height: auto;
    overflow: visible;
  }
}

.header-nav-content {
  position: relative;
  margin-top: 50px;
  @include make-col(12);

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }

  .header-nav-list {
    background-color: $brand-primary;

    li {
      padding: 10px 15px;
      color: $white;
      border-bottom: 1px solid white;
    }

    @include media-breakpoint-up(md) {
      width: 100vw;
    }

    @include media-breakpoint-up(lg) {
      background-color: transparent;

      li {
        color: $text-color;
        padding: 0;
        border-bottom: none;
      }
    }
  }
}
