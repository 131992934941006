.alphabet-nav--wrapper {
    display: flex;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }

    .alphabet--item {
        padding-right: 15px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 10px;
        }

        &:last-child {
            padding-right: 0;
        }

        a {
            &.disabled {
                color: rgba($color: $color-blue, $alpha: 0.5);
            }
        }
    }
}
