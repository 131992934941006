//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: (1140px + $grid-gutter-width);
$grid-float-breakpoint: 992px;

// Fonts
$font-family-sans-serif: "roboto-condensed", "Helvetica Neue", Arial, sans-serif;
$font-size-base: 1.3rem;
$font-size-medium: 1.1rem;
$font-size-small: 0.8rem;
$font-size-h1: 1.8rem;
$font-size-h2: 1.3rem;
$font-size-h3: $font-size-base;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$line-height-base: 1.5625;
$headings-line-height: 1.1;
$headings-font-weight: $font-weight-bold;

// Colors
$color-white: #ffffff;
$color-black: #000000;
$color-blue: #1b4170;
$color-brown-light: #a76a11;
$color-gray-dark: #707070;
$color-gray: #eae8e5;
$color-gray-medium: #d0cecc;
$color-gray-light: #f7f6f5;
$brand-primary: $color-blue;
$brand-secondary: $color-gray;
$text-color: $color-black;
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
$callout-caption-bgcolor: transparentize($color-white, 0.7);

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: $brand-secondary;
$well-color: $color-white;
$input-border-focus: $brand-primary;
