.contact-section {
  background-color: $color-gray;
  padding: 40px 15px;

  @include media-breakpoint-up(md) {
    padding: 40px 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.contact-section-inner {
  @extend .container;
}

.contact-intro {
  h2 {
    background: none;
    font-size: $font-size-h1;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
  }
}

.contact-content {
  @include make-col(12);

  .content-left, .content-right {
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }
  .content-left {
    padding-left: 0;
    padding-right: 8px;
    @include media-breakpoint-down(sm) {
        padding-right: 0;
    }
  }
  .content-right{
    padding-right: 0;
    padding-left: 8px;
    @include media-breakpoint-down(sm) {
        padding-left: 0
    }
  }
}

.contact-column {
  @include media-breakpoint-up(sm) {
    @include make-col(12);
  }
}

.contact-firstname,
.contact-lastname {
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}

@include media-breakpoint-up(sm) {
  .contact-firstname {
    padding-right: 0;
  }
}

.contact-privacy {
  display: contents;
  @include make-col(12);

  input {
    width: 20px;
    margin-top: 6px;
  }

  .contact-privacy-description {
    @extend .col;
    font-size: 1rem;
  }
}

.contact-submit {
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }
  margin-top: 15px;
}

.contact-confirmation,
.contact-error {
  display: none;
  border: 0;
  border-radius: 0;

  &.alert-success {
    background-color: $brand-primary;
    color: $color-white;

    p {
      color: $color-white;
    }
  }

  b {
    font-weight: bold;
  }

  .debug {
    font-size: $font-size-small;
  }
}

.form-control {
  border: none;
  border-radius: 0;
  background-color: $color-gray-medium;
  font-weight: $font-weight-light;
}
