.service {
  @extend .col;
  padding: 15px 0;
  margin-top: 15px;

  h2, h2.title {
    background: none;
  }

  h2.title {
    border-top: none;
    color: $color-brown-light;
  }

  h2 {
    border-top: 5px solid $brand-primary;
    padding-top: 12px;
  }

  .service-description {
    @extend .col;
    padding: 15px 25px;
    background-color: $color-gray-light;

    p {
      margin-bottom: 0;
    }

    ul {
      li {
        color: $brand-primary;
      }
    }
  }
}