.highlight {
  height: 500px;
  display: flex;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-size: cover;




  &.wo-highlight {
    @include media-breakpoint-down(md){
        background-size: contain;
        height: 311px;
    }

      @include media-breakpoint-down(xs){
          height: 165px;
      }

      @media (max-width: 320px) {
        height: 120px;
      }
  }

  &.highlight-blue {

    .highlight-caption-box {
      background-color: transparentize($brand-primary, 0.2);
    }

    @include media-breakpoint-down(md){
        height: 500px;
        background-size: cover;
    }

    @include media-breakpoint-down(xs){
        height: 400px;
        background-size: cover;
    }
  }

  &.highlight-brown {

    .highlight-caption-box {
      background-color: transparentize($color-brown-light, 0.2);

      a {
        &:hover {
          background-color: darken($color-brown-light, 8);
        }
      }
    }

    @include media-breakpoint-down(md){
        height: 500px;
        background-size: cover;
    }

    @include media-breakpoint-down(xs){
        height: 400px;
        background-size: cover;
    }
  }

  .highlight-caption {
    @extend .container;
    height: 100%;
    display: flex;
    align-items: flex-start;

    @include media-breakpoint-up(xl) {
      width: 1310px;
      max-width: 1310px;
    }

    @include media-breakpoint-down(xl) {
      width: auto;
      max-width: auto;
    }
  }

  .highlight-caption-box {
    padding: 15px 25px;
    color: $white;
    flex-wrap: wrap;

    @include media-breakpoint-up(sm) {
      @include make-col(12);
      display: block;
      top: 20%;
      padding: 35px 200px 35px 85px;
    }

    h2, p {
      color: $white;
      display: block;
    }

    h2 {
      padding-top: 0;
      color: $white !important;
      @extend h1;
      background: none;
      margin-bottom: 0;

      @include media-breakpoint-down(xs) {
          font-size: 22px !important;
      }

      @media(max-width: 320px) {
        font-size: 20px !important;
    }
    }

    p {
      font-weight: $font-weight-light;
      margin-bottom: 1.5rem;
    }

    p:last-child {
      margin-top: 1.7rem;
      margin-bottom: 12px;
    }

    a {
      @extend .btn-brand-secondary;
    }
  }
}
