h2 {
  background-image: url('../Images/layout/blue-divider.svg');
  background-size: 150px;
  background-repeat: no-repeat;
  padding-top: 15px;

  @include media-breakpoint-down(xs) {
      line-height: 1.9rem !important;
  }
}

.neos-nodetypes-text {
  &.color-primary {
    color: $brand-primary;
  }
}

.neos-nodetypes-headline {
  &.h1 {
    h2 {
      @extend h1;
      background: none;
    }
  }

  &.title {
    h1 {
    @include media-breakpoint-down(xs) {
        font-size: 1.6rem;
    }
  }
}
}

.neos-nodetypes-intro {
  p {
    font-size: $font-size-base;
  }
}

.neos-nodetypes-image, .instanode-headstart-thumbimage {
    &.noBorder {
        img {
            border: none !important;
        }
    }
}
