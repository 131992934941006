.languages {
  @include make-col(12);
  display: flex;
  z-index: 30;
  height: 30px;
  justify-content: flex-end;

  @include media-breakpoint-up(md) {
    top: 10px;
  }

  @include media-breakpoint-down(md) {
    position: relative;
    top: 4px;
    right: 35px;
    height: 14px;
  }

  @include media-breakpoint-down(sm) {
    top: 6px;
  }
}

.languages-list {
  margin-top: 22px;
  @extend .list-inline;
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-down(sm) {
    margin-top: 10px;
  }

  li {
    display: flex;
    font-size: 1rem;
    color: $brand-primary;
  }

  li + li:before {
    display: flex;
    content: '|';
    padding: 0 6px;
  }

  a {
    @include media-breakpoint-up(sm) {
      color: $brand-primary;
    }
  }

  .current a {
    font-weight: 500;
  }
}
