.footer {
  padding: 20px 0;
  background-color: $brand-primary;
  color: $white;
}

.footer-content {
  @extend .container;

  p {
    color: $white;
  }
}

.footer-about {
  @include make-col(12);
  @include media-breakpoint-up(sm) {
    @include make-col(4);
  }
  padding-left: 15px;
  font-size: 1.1rem;
  font-weight: $font-weight-light;

  a {
    color: $white;

    &:hover {
      text-decoration: underline;
      color: white;
    }
  }
}

.footer-nav {
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(8);
    @include make-col-offset(0);
  }
  
  @include media-breakpoint-down(md) {
    @include make-col(12);
  }

  a {
    color: $white;
    font-weight: $font-weight-light;

    &:hover {
      text-decoration: underline;
      color: white;
    }
  }
}

.footer-nav-list {
  font-size: 1.1rem;
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    margin-left: 15px;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: flex-end;

    li {
      margin-left: 45px;
    }
  }
  
  @include media-breakpoint-down(lg) {
    justify-content: flex-end;
    margin-top: 15px;
    
    li {
      margin-left: 25px;
      
      &:first-child {
        margin-left: 15px;
      }
    }
  }
  
  @include media-breakpoint-down(lmdg) {
    li {
      margin-left: 15px;
      
      &:first-child {
        margin-left: 15px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 10px;

    li {
      padding: 0;
      margin-left: 15px;
      display: block;
    }
  }
}
