.baer-highlight-headline-text {
    .text-with-image-head {
        background-color: transparent !important;
        border-bottom: 5px solid #1b4170 !important;

        h2, h3 {
            color: $brand-primary;
        }
    }
    
    .text-with-image-list {
        background-color: $color-gray-light !important;

        p {
            line-height: 28px !important;
        }
    }
}

.baer-teaser--wrapper {
    .neos-nodetypes-text-with-image {
        display: flex;
        flex-direction: column-reverse;
        
        h2, h3 {
            background-color: transparent !important;
            border-bottom: 5px solid #1b4170 !important;
            color: $brand-primary !important;
            padding: 16px 20px;
            margin-bottom: 0 !important;
        }
        
        figure {
            margin-bottom: 0;
        }
    }
    
    .description {
        background-color: $color-gray-light !important;
        p {
            color: $brand-primary;
        }
    }
    
    &.wo-bullets {
        li {
            list-style: none;
            
            a {
                &::after {
                    bottom: 3px !important;
                    background-image: url('../Images/layout/pfeile-blau.svg') !important;
                }
                
                &:hover {
                    color: inherit !important;
                }
            }
        }
    }
}

.baer-split-collection--wrapper {
    box-shadow: 0px 0px 18px rgba(0,0,0,0.3);
    
    .content {
        .neos-contentcollection {
            &:nth-child(2) {
                .neos-nodetypes-headline {
                    h2, h3 {
                        color: white;
                    }
                }

                .neos-nodetypes-text {
                    p {
                        color: white;
                    }
                }
            }
        }
    }
}

.baer-fq-modul {
    .neos-contentcollection {
        background-color: $color-blue !important;
    }
    .question {
        top: 0 !important;
        
        .neos-nodetypes-headline  {
            top: 0 !important;

            h2 {
                background-image: none !important;
                padding-top: 0 !important;
            }
        }
    }
}