%responsivnes {

    &.cols-2 {
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }

        @include media-breakpoint-up(lg) {
            @include make-col(2);
        }
      }

    &.cols-3 {
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }

        @include media-breakpoint-up(lg) {
            @include make-col(3);
        }
    }

    &.cols-4 {
    @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(6);
        }

        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
    }

    &.cols-6 {
    @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(12);
        }

        @include media-breakpoint-up(lg) {
            @include make-col(6);
        }
    }

    &.cols-12 {
        @include make-col(12);
    }
}
