body {
  > .content {
    @extend .container-fluid;
    margin: auto;
    min-height: calc(100vh);

    img {
      @extend .img-fluid;
    }

    ul {
      padding-left: 20px;

      li {
        list-style-type: square;
        font-size: $font-size-medium;
        font-weight: $font-weight-light;
      }
    }

    ol {
        padding-left: 20px;

        li {
            font-size: $font-size-medium;
            font-weight: $font-weight-light;
            color: $color-blue;
        }
    }

    table {
      @extend .table;
    }
  }
}

h1, h2, h3 {
  color: $brand-primary;
}

h1 {
  color: $brand-primary;
  font-size: $font-size-h1;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-bottom: 0.9rem;
  line-height: 35px;
}

h2 {
  font-size: $font-size-h2;
  font-weight: $font-weight-normal;
  line-height: 1.625rem;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: $font-size-h3;
  font-weight: $font-weight-normal;
}

p {
  font-size: $font-size-medium;
  font-weight: $font-weight-light;
  color: $brand-primary;
  margin-bottom: 0.8rem;

  a {
    font-weight: $font-weight-bold;

    &:hover {
      color: $brand-primary;
      text-decoration: underline;
    }
  }
}

h2 > p {
  font-size: $font-size-medium;
}

ul {

  li {
    color: $brand-primary;
  }
}

.btn-brand-primary {
  border-radius: 0;
  border: 1px solid $brand-primary;
  background-color: transparent;
  color: $brand-primary;
  padding: 8px 15px;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  font-weight: $font-weight-normal;
  letter-spacing: 0.5px;
  font-size: $font-size-medium;

  @include media-breakpoint-up(sm) {
    padding: 8px 25px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    padding: 8px 15px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 15px;
  }

  @media (max-width:320px) {
      font-size: 14px;
  }

  &:hover {
    text-decoration: none;
    background-color: $brand-primary;
    color: $white;
  }
}

.btn-brand-secondary {
  @extend .btn-brand-primary;
  border: 1px solid $white;
  color: $white;

  &:hover {
    text-decoration: none;
    background-color: $brand-primary;
    color: $white;
  }
}

.neos-contentcollection {
  @include clearfix;
}
