.carousel {
  margin: $line-height-base auto;

  .carousel-control {
    background-image: none;
  }

  .carousel-control-prev {

    @media (max-width: 1440px) {
      left: 10px;
      width: auto;
    }

    @include media-breakpoint-down(lg) {
      left: 2px;
    }

    @include media-breakpoint-down(md) {
        left: 0;
    }

    @include media-breakpoint-down(sm) {
      left: 4px;
    }


    .carousel-control-prev-icon {
      background-image: url('../Images/layout/slider_pfeil_weiß.svg');
      width: 45px;
      height: 45px;

        @include media-breakpoint-down(md) {
            width: 30px;
            height: 30px;
        }
    }
  }

    .carousel-control-next {
      right: -0;

      @media (max-width: 1440px) {
        right: 10px;
        width: auto;
      }

      @include media-breakpoint-down(lg) {
        right: 2px;
      }

      @include media-breakpoint-down(md) {
          right: 0;
      }

      @include media-breakpoint-down(sm) {
        right: 4px;
      }

      .carousel-control-next-icon {
        background-image: url('../Images/layout/slider_pfeil_weiß.svg');
        width: 45px;
        height: 45px;
        transform: rotate(-180deg);

          @include media-breakpoint-down(md) {
              width: 30px;
              height: 30px;
          }
      }
    }
}
