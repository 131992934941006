.industry {
  border-top: 5px solid $brand-primary;
  padding-top: 15px;
  margin-bottom: 50px;

    h2 {
      background: none;
      height: auto;
      padding-top: 0;
    }

  .industry-inner-content {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }

    .industry-image {
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      margin-top: 0;

      @include media-breakpoint-down(xs) {
        height: 210px;
        
        img {
          max-height: 210px;
          object-fit: contain;
        }
      }

      a, div {
        display: flex;
        figure {
          display: flex;
          align-items: center;
          margin-bottom: 0;

          img {
            width: 100%;
            max-height: 145px;
          }
        }
      }
    }

    .industry-description {
      @include make-col(12);
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      padding-right: 35px;

      p {
        margin-bottom: 0.1rem;
      }

      ul {
        margin-bottom: 1.5rem;
      }

      p.industry-download-link {
        padding-top: 25px;

        a.btn-brand-primary {
          &:hover {
            text-decoration: none;
            background-color: $brand-primary;
            color: $white;
          }
        }
      }
    }

    h3 {
      line-height: 1.3rem;
      margin-bottom: 17px;
    }
  }

  &.industry-secondary {

    .industry-inner {
      border-top: 5px solid $color-brown-light;
      padding-top: 15px;

      h3 {
        a {
          color: $color-brown-light;
        }
      }

      .industry-description {
        p {
          color: $color-black;
        }

        ul {
          li {
            color: $color-black;
          }
        }
      }
    }
  }

  &.col-12 {
    .industry-image {
      @include make-col(3);
    }
  }

}
