.teaser-collection {
    .neos-contentcollection {
        display: flex;
        flex-wrap: wrap;
        @extend .row;
        .teaser {
            margin-left: 0;
        }
    }
}

.teaser {
  @extend .col;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  min-height: 250px;
  margin-left: -15px;
  @extend %responsivnes;

  &:nth-child(3) {
      .teaser-inner {
          .teaser-image {
              figure > img {
                height: 100%;
              }
         }
    }
  }

  .teaser-inner {
    border-top: 5px solid $brand-primary;
    padding-top: 15px;

    a.btn-brand-primary {
      display: inline-block;
      margin-top: 15px;
    }
  }

  &.image {

    .teaser-image {
      background-color: transparent;

      a, div {
        figure {
          align-items: flex-start;
          height: 180px;

          img {
            max-height: 180px;
          }
        }
      }
    }
  }

  .teaser-image {
    display: flex;
    background-color: $color-gray-light;
    width: 100%;
    height: 175px;

    a, div {
      display: flex;
      figure {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        img {
          width: 100vw;
          max-height: 145px;
        }
      }
    }
  }

  h3 {
    line-height: 1.3rem;
    margin-bottom: 17px;
    color: $color-blue;
  }

  &.cols-4, &.cols-2 {
    &:nth-child(3) {
      .teaser-image {
          figure > img {
                height: auto;
            }
          }
      }
  }
  //   &.cols-3 {
//     padding: 15px;

//     &:nth-child(4n) {
//         padding-right: 0;
//     }
//     &:nth-child(4n+1) {
//         margin-left: -15px;
//     }
//   }

//   &.cols-6 {
//     padding-right: 30px;

//     &:nth-child(2n) {
//         padding-right: 0;
//     }
//   }
}
